import React from 'react';
import * as Icon from 'react-feather';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerHome: IRouter = {
  path: '/files',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'home.name', //translate here for breadcrumb and sidebar
  menu: {
    icon: <Icon.FileText />,
  },
  // permissionCode: PermissionCode.DirectoriesView,
};

export const routerHomeFolder: IRouter = {
  path: '/files/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'home.name', //translate here for breadcrumb and sidebar
  // permissionCode: PermissionCode.DirectoriesView,
};
