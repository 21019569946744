import lodash from 'lodash';
import moment from 'moment';

export const indexOfArrayObject = (array: any[], key: string, value: any) => {
  if (!Array.isArray(array)) {
    return;
  }
  let index = -1;
  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    if (item[key] === value) {
      index = i;
      break;
    }
  }
  return index;
};

export const debounce = (callback: any, delay: number) => {
  return lodash.debounce(callback, delay);
};

export const onScrollBottom = (callBack: any) => {
  window.onscroll = function (event) {
    if (window.innerHeight + window.scrollY > document.body.offsetHeight) {
      callBack(event);
    }
  };
};

export function roundToTwo(num: string) {
  return Number.parseFloat(num).toFixed(2);
}

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  const random = Math.random();
  return Math.floor(random * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}
export function isValidHttpUrl(string: string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}

export const spliceArray = (arr: Array<any>, start: number, end: number) => {
  return [...arr].splice(start, end);
};

export const getCookie = (cname: string) => {
  const name = cname + '=';
  const decodedCookie: string = decodeURIComponent(document.cookie) || '';
  if (decodedCookie == null || decodedCookie === '') {
    return '';
  }
  const cookieValue = decodedCookie
    .split('; ')
    ?.find(row => row.startsWith(name))
    ?.split('=')[1];
  return cookieValue || '';
};

export const toFirstUpperCase = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const toFirstLowerCase = (string: string) => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const delay = miliSecond => new Promise(resolve => setTimeout(resolve, miliSecond));

export function sessionStorageSetItem(key: string, data: any) {
  sessionStorage.setItem(key, JSON.stringify(data));
}

export function sessionStorageGetItem(key: string) {
  const data = sessionStorage.getItem(key);
  if (data) {
    return JSON.parse(data);
  }
  return null;
}

export function processMemberData(memberData) {
  const newRes = { ...memberData };
  newRes.dayOfBirth = moment(memberData?.dayOfBirth);
  if (newRes?.customFee === null || newRes?.customFee === undefined) {
    newRes.customFee = memberData?.rank?.currentFee;
  }

  if (memberData?.learderInfo === null) {
    newRes.leaderInfoName = null;
    newRes.leaderInfoPhoneNumber = null;
    newRes.leaderInfoGender = null;
    newRes.leaderInfoEmailAddress = null;
    newRes.leaderInfoPosition = null;
    newRes.leaderInfoDateOfBirth = null;
  } else {
    newRes.leaderInfoName = memberData?.learderInfo?.name;
    newRes.leaderInfoPhoneNumber = memberData?.learderInfo?.phoneNumber;
    newRes.leaderInfoGender = memberData?.learderInfo?.gender;
    newRes.leaderInfoEmailAddress = memberData?.learderInfo?.emailAddress;
    newRes.leaderInfoPosition = memberData?.learderInfo?.position;
    newRes.leaderInfoDateOfBirth = memberData?.learderInfo?.dateOfBirth
      ? moment(memberData?.learderInfo?.dateOfBirth)
      : null;
  }

  newRes.enterpriseIds = memberData?.memberEnterprises
    ?.sort((a, b) => {
      if (a.enterprise?.id < b.enterprise?.id) {
        return -1;
      }
      if (a.enterprise?.id > b.enterprise?.id) {
        return 1;
      }
      return 0;
    })
    ?.map(data => data?.enterprise?.id);
  newRes.organizationIds = memberData?.memberOrganizations
    ?.sort((a, b) => {
      if (a?.organization?.id < b?.organization?.id) {
        return -1;
      }
      if (a?.organization?.id > b?.organization?.id) {
        return 1;
      }
      return 0;
    })
    ?.map(data => data?.organization?.id);

  newRes.connectInfos2 = memberData?.connectInfos;
  newRes.fieldRelationIds = [];
  memberData?.memberFields
    ?.sort((a, b) => {
      if (a.fieldRelationId < b.fieldRelationId) {
        return -1;
      }
      if (a.fieldRelationId > b.fieldRelationId) {
        return 1;
      }
      return 0;
    })
    ?.forEach(it => {
      newRes?.fieldRelationIds?.push(it?.fieldRelationId);
    });
  if (memberData?.dayOfBirth == null) {
    newRes.dayOfBirth = undefined;
  }

  newRes.marketIds = memberData?.memberMarkets
    ?.sort((a, b) => {
      if (a?.market?.id < b?.market?.id) {
        return -1;
      }
      if (a?.market?.id > b?.market?.id) {
        return 1;
      }
      return 0;
    })
    ?.map(data => data?.market?.id);
  newRes.targetCustomerIds = memberData?.memberTargetCustomers
    ?.sort((a, b) => {
      if (a?.targetCustomer?.id < b?.targetCustomer?.id) {
        return -1;
      }
      if (a?.targetCustomer?.id > b?.targetCustomer?.id) {
        return 1;
      }
      return 0;
    })
    ?.map(data => data?.targetCustomer?.id);
  newRes.certificateIds = memberData?.memberCertificates
    ?.sort((a, b) => {
      if (a?.certificate?.id < b?.certificate?.id) {
        return -1;
      }
      if (a?.certificate?.id > b?.certificate?.id) {
        return 1;
      }
      return 0;
    })
    ?.map(data => data?.certificate?.id);
  newRes.clubIds = memberData?.memberClubs?.map(data => data?.clubId);
  newRes.headOfficeAddressProvinces = memberData?.headOfficeAddress?.province?.fullName;
  newRes.headOfficeAddressDistricts = memberData?.headOfficeAddress?.district?.fullName;
  newRes.headOfficeAddressCommunes = memberData?.headOfficeAddress?.commune?.fullName;
  newRes.headOfficeAddressRoad = memberData?.headOfficeAddress?.road;
  newRes.transactionOfficeAddressProvinces =
    memberData?.transactionOfficeAddress?.province?.fullName;
  newRes.transactionOfficeAddressDistricts =
    memberData?.transactionOfficeAddress?.district?.fullName;
  newRes.transactionOfficeAddressCommunes = memberData?.transactionOfficeAddress?.commune?.fullName;
  newRes.transactionOfficeAddressRoad = memberData?.transactionOfficeAddress?.road;
  newRes.avatar = memberData?.avatarPath;
  return newRes;
}

export function compareObjects(obj1: any, obj2: any) {
  const result: any = {};

  for (const key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      const val1 = obj1[key];
      const val2 = obj2 ? obj2[key] : undefined;

      if (moment.isMoment(val1) && moment.isMoment(val2)) {
        result[key] = !val1.isSame(val2);
      } else if (typeof val1 === 'object' && !Array.isArray(val1) && val1 !== null) {
        result[key] = compareObjects(val1, val2);
      } else if (Array.isArray(val1) && Array.isArray(val2)) {
        if (key === 'connectInfos') {
          result[key] = val1.map((item, index) => compareObjects(item, val2[index]));
        } else {
          // So sánh 2 mảng mà không quan tâm đến thứ tự các phần tử
          const sortedVal1 = [...val1].sort();
          const sortedVal2 = [...val2].sort();
          result[key] =
            sortedVal1.length !== sortedVal2.length ||
            !sortedVal1.every((item, index) => {
              if (typeof item === 'object') {
                return JSON.stringify(item) === JSON.stringify(sortedVal2[index]);
              }
              return item === sortedVal2[index];
            });
        }
      } else {
        result[key] = val1 !== val2;
      }
    }
  }

  return result;
}

export function checkKeyInObject(itemName, obj) {
  if (!obj) {
    return false;
  }

  // Nếu itemName là chuỗi, chuyển thành mảng bằng cách tách theo dấu chấm '.'
  const keys = Array.isArray(itemName) ? itemName : itemName.split('.');
  let current = obj;

  for (const key of keys) {
    // Kiểm tra xem key có tồn tại trong current (object hoặc array) hay không
    if (current.hasOwnProperty(key) || (Array.isArray(current) && key in current)) {
      current = current[key];
    } else {
      return false;
    }
  }

  // Kiểm tra cuối cùng xem giá trị có bằng true không
  return current === true;
}

export const getFileType = v => {
  switch (v) {
    case 'application/msword':
    case 'application/doc':
    case 'application/ms-doc':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'doc';
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case '.csv':
      return 'xls';
    case 'application/pdf':
      return 'pdf';
    case 'folder':
      return 'folder';
    default:
      return 'Undefined';
  }
};

export const checkNameUndefinedFile = array => {
  if (array?.some(item => !item?.name) || !array || array?.length === 0) {
    return null;
  } else {
    return array;
  }
};

export const convertBytesToMB = bytes => {
  if (isNaN(bytes) || bytes < 0) {
    throw new Error('Giá trị đầu vào phải là số và lớn hơn hoặc bằng 0.');
  }
  const mb = bytes / (1024 * 1024);
  return parseFloat(mb.toFixed(2));
};
