export default {
  'home.name': 'Tài liệu',
  'home.title': 'Tài liệu lưu trữ',
  'home.fileName': 'Tên File',
  'home.fullPath': 'Đường dẫn',
  'home.contentType': 'Loại file',
  'home.createdAt': 'Ngày tạo',
  'home.confirm.title.delete': 'Xóa tài liệu',
  'home.confirm.content.delete': 'Bạn đồng ý xóa những thư mục và file đã chọn?',
  'home.create': 'Thêm mới file',
  'home.action': 'Hành Động',
  'home.memberSelect': 'Đối tượng',
  'home.memberSelect-placeholder': 'Chọn đối tượng',
  'home.accepts': 'Đối tượng',
  'home.upload': 'Tải lên file',
  'home.clubs': 'Câu lạc bộ',
  'home.formAdd.code': 'Mã hội viên',
  'home.formAdd.enterpriseName': 'Câu lạc bộ',
  'home.formAdd.emailAddress': 'Email',
  'home.formAdd.type': 'Loại thành viên',
  'home.formAdd.name': 'Tên hội viên',
  'home.maxFile': 'Không thể thêm nhiều hơn cùng lúc nhiều hơn 1 file',
  'home.requiredFile': 'Chọn file tải lên',
  'dashboard.name': 'Trang chủ',
  'home.input.name': 'Tên file',
  'home.placeholder.input.name': 'Nhập tên file',
  'home.validate-file': 'Chỉ được tải lên một tệp duy nhất và dung lượng không vượt quá 60MB.',
  'home.upload.content': 'Hỗ trợ tải lên 1 file duy nhất',
  'home.folder.information': 'Thông tin Folder',
  'home.folder.update': 'Chỉnh sửa Folder',
  'home.folder.create': 'Thêm mới Folder',
  'home.folder.input.name': 'Tên folder',
  'home.folder.placeholder.input.name': 'Nhập tên folder',
  'home.addFolder': 'Thêm mới folder',
  'home.uploadFile': 'Tải lên file',
  'home.editFile': 'Chỉnh sửa file',
  'home.downloadFile': 'Tải xuống file',
  'home.upload.file.title': 'Nhấp hoặc kéo tệp vào đây',
  'home.upload.file.content': 'Tệp hỗ trợ: PDF, Word, Excel',
  'home.modal.upload': 'Tải lên tệp',
  'home.modal.change.name': 'Đổi tên file',
  'home.file.validate': 'Nhập file và tên file không được để trống',
  'home.file.delete': 'Xóa thành công',
  'home.sort': 'Sắp xếp',
  'home.sort.by-name.asc': 'Theo tên tăng dần',
  'home.sort.by-name.desc': 'Theo tên giảm dần',
  'home.sort.by-createdDate.asc': 'Theo ngày tạo tăng dần',
  'home.sort.by-createdDate.desc': 'Theo ngày tạo giảm dần',
  'home.select-all': 'Chọn tất cả',
  'home.un-select-all': 'Bỏ chọn tất cả',
  'home.information': 'Chi tiết file',
  'home.update': 'Chỉnh sửa file',
  'home.changeFile': 'Thay đổi file',
  'home.home.validate': 'Tên folder không được để trống',
  'home.file.name.validate': 'Tên file không được để trống',
};
