export default {
  'role.name': 'Manage role',
  'role.userManager': 'User management',
  'roles.name': 'Role',
  'roles.roleName': 'Name role',
  'roles.rolePermissions': 'Permission',
  'roles.permissionCodes': 'Permission',
  'roles.createdAt': 'Date created',
  'roles.action': 'Action',
  'roles.delete.title': 'Delete role',
  'roles.delete.content': 'Do you want to delete this role?',
  'Per.Users.Create': 'Add User',
  'Per.Users.Delete': 'Delete user',
  'Per.Users.Edit': 'Edit user',
  'Per.Users.Export': 'Export user',
  'Per.Users.View': 'See user details',
  'roles.create': 'Create a role',
  'roles.information': 'Role information',
  'roles.update': 'Update role',
  'Mes.Users.Role.UpdateSuccessfully': 'Update the role of success',
  'Mes.Roles.Add.Successfully': 'Add a success role',

  'common.checkAll': 'All',
  'roles.add.name': 'Add role',
  'roles.edit.name': 'Update role',
  'roles.info.name': 'Role information',
  'role.confirm.title.delete': 'Do you want to erase the role?',

  'permission.BatchsView': 'See details of the prize management information',

  'permission.CodeSubmitsExport': 'Export the historical file to change code',

  'permission.CodeSubmitsView': 'See detailed information about changing code',

  'permission.LuckyDrawsRevokePrize': 'Revoke the prize',

  'permission.LuckyDrawsSendSms': 'Send SMS',

  'permission.LuckyDrawsUpdate': 'Lucky drawing information',

  'permission.LuckyDrawsView': 'See lucky draw details',

  'permission.ModeSettingsCreate': 'Add ratio',

  'permission.ModeSettingsDelete': 'Delete the rate',

  'permission.ModeSettingsUpdate': 'Ratio information update',

  'permission.ModeSettingsView': 'See detailed information',

  'permission.PrizesCreate': 'Add award',

  'permission.PrizesDelete': 'Delete prizes',

  'permission.PrizesUpdate': 'Award information update',

  'permission.PrizesView': 'See detailed information',

  'permission.RedeemCodesBlock': 'Lock code',

  'permission.RedeemCodesImport': 'Insert code',

  'permission.RedeemCodesView': 'See details code',

  'permission.RedeemsSpin': 'Fun prize',

  'permission.RolesCreate': 'Add role',

  'permission.RolesDelete': 'Delete the role',

  'permission.RolesUpdate': 'Update information',

  'permission.RolesView': 'See detailed information',

  'permission.RoundsCreate': 'Add round',

  'permission.RoundsDelete': 'Delete roound',

  'permission.RoundsUpdate': 'Update information Round',

  'permission.RoundsView': 'See detailed information',

  'permission.UsersCreate': 'More users',

  'permission.UsersDelete': 'Delete users',

  'permission.UsersUpdate': 'Update user information',

  'permission.UsersView': 'See details of user information',

  'permission.CodeSubmitLogsView': 'See detailed information management information',

  'permission.TopUpsReTopUp': 'reTopUp',

  'permission.LuckyDrawsImport': 'Enter the list of winning prizes',

  'permission.LuckyDrawsExport': 'Export the winning list of prizes',

  'permission.CodeSubmitLogsExport': 'Export the code list of code',

  'role.Careers': 'Branch',

  'permission.CareersCreate': 'Create industry',

  'permission.CareersDelete': 'Delete industry',

  'permission.CareersSearch': 'View industry list',

  'permission.CareersUpdate': 'Industry update',

  'permission.CareersView': 'See industry details',

  'role.Certificates': 'Certificate',

  'permission.CertificatesCreate': 'Create certificates',

  'permission.CertificatesDelete': 'Delete certificates',

  'permission.CertificatesSearch': 'See a list of certificates',

  'permission.CertificatesUpdate': 'Certificate update',

  'permission.CertificatesView': 'See details',

  'role.Clubs': 'Club',

  'permission.ClubsCreate': 'Create club',

  'permission.ClubsDelete': 'Delete the club',

  'permission.ClubsSearch': 'View Club list',

  'permission.ClubsUpdate': 'Club update',

  'permission.ClubsView': 'See the details of the club',

  'permission.ClubsCreateMember': 'Create club members',

  'permission.ClubsCreatePartner': 'Create a club partner',

  'permission.ClubsInvite': 'Invites club members',

  'role.Contacts': 'Contact',

  'permission.ContactsUpdate': 'Update contact',

  'role.Enterprises': 'Enterprise',

  'permission.EnterprisesCreate': 'Create business',

  'permission.EnterprisesDelete': 'Delete business',

  'permission.EnterprisesSearch': 'See list of businesses',

  'permission.EnterprisesUpdate': 'Business update',

  'permission.EnterprisesView': 'See business details',

  'role.ExecutiveCommittees': 'Executive Board',

  'permission.ExecutiveCommitteesCreate': 'Create an executive committee',

  'permission.ExecutiveCommitteesDelete': 'Delete the Executive Board',

  'permission.ExecutiveCommitteesSearch': 'See the list of executives',

  'permission.ExecutiveCommitteesUpdate': 'Update the Executive Board',

  'permission.ExecutiveCommitteesView': 'See the Executive Board details',

  'role.Fees': 'Fees',

  'permission.FeesCreate': 'Create fees',

  'permission.FeesDelete': 'Delete fees',

  'permission.FeesSearch': 'See the list of fees',

  'permission.FeesUpdate': 'Update the festival',

  'permission.FeesView': 'See the details of the fees',

  'role.Fields': 'Field',

  'permission.FieldsCreate': 'Create field',

  'permission.FieldsDelete': 'Delete field',

  'permission.FieldsSearch': 'View field list',

  'permission.FieldsUpdate': 'Update field',

  'permission.FieldsView': 'See details',

  'role.Markets': 'Market',

  'permission.MarketsCreate': 'Create market',

  'permission.MarketsDelete': 'Delete the market',

  'permission.MarketsSearch': 'See market lists',

  'permission.MarketsUpdate': 'Market update',

  'permission.MarketsView': 'See market details',

  'role.Medias': 'File',

  'permission.MediasCreate': 'Create file',

  'permission.MediasDelete': 'Delete file',

  'role.Members': 'Member',

  'permission.MembersCreate': 'Create members',

  'permission.MembersDelete': 'Delete members',

  'permission.MembersSearch': 'See list of members',

  'permission.MembersUpdate': 'Members update',

  'permission.MembersView': 'See details members',

  'role.Notifications': 'Notification',

  'permission.NotificationsCreate': 'Create notification',

  'permission.NotificationsDelete': 'Delete notice',

  'permission.NotificationsSearch': 'See the notice list',

  'permission.NotificationsUpdate': 'Update notice',

  'permission.NotificationsView': 'See details',

  'role.Organizations': 'Organization',

  'permission.OrganizationsCreate': 'Create organization',

  'permission.OrganizationsDelete': 'Organization deletion',

  'permission.OrganizationsSearch': 'View Organization List',

  'permission.OrganizationsUpdate': 'Organization update',

  'permission.OrganizationsView': 'See organization details',

  'role.Partners': 'Partner',

  'permission.PartnersCreate': 'Create partners',

  'permission.PartnersDelete': 'Delete partners',

  'permission.PartnersSearch': 'See a list of partners',

  'permission.PartnersUpdate': 'Update partner',

  'permission.PartnersView': 'See partner details',

  'role.Ranks': 'Member rank',

  'permission.RanksSearch': 'View list of member ranks',

  'permission.RanksUpdate': 'Update member rank',

  'permission.RanksView': 'View details of member rank',

  'role.Roles': 'Role',

  'permission.RolesExport': 'Role role',

  'permission.RolesSearch': 'See the role list',

  'role.Schedules': 'Calendar',

  'permission.SchedulesCancel': 'Cancel calendar',

  'permission.SchedulesCreate': 'Calendar',

  'permission.SchedulesDelete': 'Delete the calendar',

  'permission.SchedulesSearch': 'See the calendar list',

  'permission.SchedulesUpdate': 'Calendar update',

  'permission.SchedulesView': 'See details',

  'role.Sponsors': 'Sponsor',

  'permission.SponsorsCreate': 'Create funding',

  'permission.SponsorsDelete': 'Delete funding',

  'permission.SponsorsSearch': 'View funding list',

  'permission.SponsorsUpdate': 'Sponsor update',

  'permission.SponsorsView': 'See sponsorship details',

  'role.TargetCustomers': 'Targeted customer',

  'permission.TargetCustomersCreate': 'Create target customers',

  'permission.TargetCustomersDelete': 'Delete target customers',

  'permission.TargetCustomersSearch': 'See the target customer list',

  'permission.TargetCustomersUpdate': 'Target customer update',

  'permission.TargetCustomersView': 'See details target customers',

  'role.Users': 'User',

  'permission.UsersExport': 'Export user file',

  'permission.UsersSearch': 'See list of users',

  'role.Represents': 'Represent',

  'permission.RepresentsCreate': 'Create representation',

  'permission.RepresentsDelete': 'Delete representatives',

  'permission.RepresentsSearch': 'See a list of representatives',

  'permission.RepresentsUpdate': 'Representative update',

  'permission.RepresentsView': 'See details',

  'permission.ClubsDeleteMember': 'Delete club members',

  'permission.ClubsDeletePartner': 'Delete club members',

  'permission.ClubsUpdateMember': 'Club member update',

  'permission.ClubsUpdatePartner': 'Club partners update',

  'permission.ClubsImportMember': 'Import Membership',
  'permission.ClubsImportPartner': 'Import Partners',
  'permission.MembersImport': 'Import Membership',
  'permission.ExecutiveCommitteesImport': 'Import Executive Committee',
  'permission.PartnersImport': 'Import Partners',
  'permission.RepresentsImport': 'Import Executive Board',

  // Activities
  'role.Activities': 'Activities',
  'permission.ActivitiesCreate': 'Create activities',
  'permission.ActivitiesCreateSub': 'Create child activities',
  'permission.ActivitiesDelete': 'Delete activities',
  'permission.ActivitiesDeleteSub': 'Delete child activities',
  'permission.ActivitiesSearch': 'View detail activities',
  'permission.ActivitiesUpdate': 'Update activities',
  'permission.ActivitiesUpdateSub': 'Update child activities',
  'permission.ActivitiesView': 'View detail activities',

  // Feedback
  'role.Feedbacks': 'Feedbacks',
  'permission.FeedbacksReply': 'Reply',
  'permission.FeedbacksSearch': 'View list of feedbacks',
  'permission.FeedbacksView': 'View detail feedbacks',

  // News
  'role.Documents': 'News',
  'permission.DocumentsCreate': 'Create News',
  'permission.DocumentsDelete': 'Delete News',
  'permission.DocumentsSearch': 'View list of News',
  'permission.DocumentsView': 'View detail of News',
  'permission.DocumentsUpdate': 'Update News',

  // Rank update 14/08/2024
  'permission.RanksCreate': 'Create member rank',
  'permission.RanksDelete': 'Delete member rank',

  // Modification
  'role.MemberModifications': 'Approve Modifications',
  'permission.MemberModificationsSearch': 'View Approval List',
  'permission.MemberModificationsView': 'View Approval Details',
  'permission.MemberModificationsConfirm': 'Confirm',

  // Directories
  'role.Directories': 'Document',
  'permission.DirectoriesCreate': 'Create folder',
  'permission.DirectoriesDelete': 'Delete folder',
  'permission.DirectoriesUpdate': 'Update fodler',
  'permission.DirectoriesView': 'View Documents',

  'permission.MediasUpdate': 'Xóa file',
};
