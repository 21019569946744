export default {
  // 'server.notFound': 'Not found',
  // 'server.networkError': 'Network error',
  // 'Server.AddBeat.Success': 'Adding success',
  // 'Server.DeleteBeat.Success': 'Deleting success',
  // 'Server.UpdateBeat.Success': 'Updating success',
  // 'Mes.Users.Add.Successfully': 'Add successful account',
  // 'Mes.Users.DeleteUsers.Successfully': 'Successfully erase',
  // 'Mes.Users.UpdateSuccessfully': 'successfulAccountInformationUpdates',
  // 'Mes.Researchs.Update.Successfully': 'Updating successful research',
  // 'Mes.Researchs.Add.Successfully': 'Add a successful study',
  // 'Mes.Researchs.Delete.Successfully': 'Delete successful research','
  'Mes.Notification.Id.Required': 'No recipients added yet',
  'Mes.Notification.NotFound': 'Can not delete sent message',
  'Mes.Notification.Title.Required': 'Please enter title notification',
  'Mes.Notification.Title.OverLength': 'Title is too long',
  'Mes.Notification.Content.Required': 'Invalid content',
  'Mes.Notification.Content.OverLength': 'Content is too long',
  'Mes.Notification.Method.Required': 'Invalid form',
  'Mes.Notification.Method.InValid': 'Invalid form',
  'Mes.Notification.Type.Required': 'Please enter type notification',
  'Mes.Notification.Type.InValid': 'Illegal',
  'Mes.Notification.SendTime.Required': 'Invalid submission time',
  'Mes.Notification.SendTime.InValid': 'Invalid submission time',
  'Mes.Notification.MemberIds.Duplicate': 'Duplicate recipients',
  'Mes.Notification.MemberIds.Required': 'Invalid Recipient',
  'Mes.Notification.OtherEmails.Duplicate': 'Email is duplicated',
  'Mes.Notification.Create.Successfully': 'Add success message',
  'Mes.Notification.Update.Successfully': 'Update success message',
  'Mes.Notification.Update.Failed': 'Update failed message',
  'Mes.Notification.Delete.Successfully': 'Delete success message',
  'Mes.Notification.GetDetail.Successfully': 'Notice details',
  'Mes.Notification.Search.Successfully': 'Successful search',
  'Mes.OtherEmail.Name.Required': 'No outsider added yet',
  'Mes.OtherEmail.EmailAddress.Required': 'No outsider added yet',
  'Failed to read the request form. Request body too large. The max request body size is 30000000 bytes.':
    'The total size of attachments cannot exceed 100 Mb',
  'Mes.Schedule.NotFound': 'Invalid id',
  'Mes.Schedule.Id.Required': 'Does not exist',
  'Mes.Schedule.MeetingHostName.Required': 'Please enter host name',
  'Mes.Schedule.MeetingHostName.OverLength': 'Host name is too long',
  'Mes.Schedule.Title.Required': 'Please enter title schedule',
  'Mes.Schedule.Title.OverLength': 'Title is too long',
  'Mes.Schedule.Content.Required': 'Please enter content schedule',
  'Mes.Schedule.Content.OverLength': 'Content is too long',
  'Mes.Schedule.Address.Required': 'Please enter address schedule',
  'Mes.Schedule.Address.OverLength': 'The address is too long',
  'Mes.Schedule.Type.Required': 'Please enter type schedule',
  'Mes.Schedule.Type.InValid': 'Illegal',
  'Mes.Schedule.StartTime.Required': 'Please enter start time schedule',
  'Mes.Schedule.EndTime.Required': 'Please enter end time schedule',
  'Mes.Schedule.EndTime.InValid': 'Invalid end time',
  'Mes.Schedule.MemberIds.Duplicate': 'Duplicate recipients',
  'Mes.Schedule.MemberIds.Required': 'Invalid Recipient',
  'Mes.Schedule.OtherEmails.Duplicate': 'Email is duplicated',
  'Mes.Schedule.Create.Successfully': 'Add successful calendar',
  'Mes.Schedule.Create.Failed': 'Add failure calendar',
  'Mes.Schedule.Update.Successfully': 'Calendar update successful',
  'Mes.Schedule.Update.Failed': 'Calendar update failed',
  'Mes.Schedule.Delete.Successfully': 'Canceled successfully',
  'Mes.Schedule.GetDetail.Successfully': 'Calendar details',
  'Mes.Schedule.Search.Successfully': 'Successful search',
  'Mes.Schedule.Cancel.Successfully': 'Canceled successfully',
  'Mes.Career.NotFound': 'Not found',
  'Mes.Career.Name.Required': 'Please enter name career',
  'Mes.Career.Name.OverLength': 'Name is too long',
  'Mes.Career.Description.OverLength': 'Description is too long',
  'Mes.Career.Code.Required': 'Please enter code career',
  'Mes.Career.Code.OverLength': 'The code is too long',
  'Mes.Career.Code.AlreadyExist': 'Code already exists',
  'Mes.Career.Create.Successfully': 'Successfully created',
  'Mes.Career.Update.Successfully': 'Update successful',
  'Mes.Career.Delete.Successfully': 'Successfully deleted',
  'Mes.Career.GetDetail.Successfully': 'Get details successfully',
  'Mes.Career.Search.Successfully': 'Successful Search',
  'Mes.Certificate.Id.Required': 'Please enter id certification',
  'Mes.Certificate.NotFound': 'Not found',
  'Mes.Certificate.Name.Required': 'Please enter name certification',
  'Mes.Certificate.Name.OverLength': 'Name is too long',
  'Mes.Certificate.Description.OverLength': 'Description is too long',
  'Mes.Certificate.Code.Required': 'Please enter code certificate',
  'Mes.Certificate.Code.OverLength': 'The code is too long',
  'Mes.Certificate.Code.AlreadyExist': 'Code already exists',
  'Mes.Certificate.Create.Successfully': 'Successfully created',
  'Mes.Certificate.Update.Successfully': 'Update successful',
  'Mes.Certificate.Delete.Successfully': 'Deleted successfully',
  'Mes.Certificate.GetDetail.Successfully': 'Get details successfully',
  'Mes.Certificate.Search.Successfully': 'Search success',
  'Mes.Organization.Id.Required': 'Please enter id organization',
  'Mes.Organization.NotFound': 'Not found',
  'Mes.Organization.Name.Required': 'Please enter name organization',
  'Mes.Organization.Name.OverLength': 'Name is too long',
  'Mes.Organization.Description.OverLength': 'Description is too long',
  'Mes.Organization.Code.Required': 'Please enter code organization',
  'Mes.Organization.Code.OverLength': 'The code is too long',
  'Mes.Organization.Code.AlreadyExist': 'Code already exists',
  'Mes.Organization.Create.Successfully': 'Successfully created',
  'Mes.Organization.Update.Successfully': 'Updated successful',
  'Mes.Organization.Delete.Successfully': 'Deleted successfully',
  'Mes.Organization.GetDetail.Successfully': 'Get details successfully',
  'Mes.Organization.Search.Successfully': 'Successful Search',
  'Mes.Rank.NotFound': 'Not found',
  'Mes.Rank.Name.Required': 'Please enter rank name',
  'Mes.Rank.Name.OverLength': 'Name is too long',
  'Mes.Rank.FutureFee.Required': 'Please enter for rank future fee',
  'Mes.Rank.ApplyFutureYear.Required': 'Please enter rank apply future year ',
  'Mes.Rank.ApplyFutureYear.InValid': 'Invalid apply year',
  'Mes.Rank.Description.OverLength': 'Description is too long',
  'Mes.Rank.Update.Successfully': 'Update successful',
  'Mes.Rank.GetDetail.Successfully': 'Get details successfully',
  'Mes.Rank.Search.Successfully': 'Search success',
  'Mes.Enterprise.Id.Required': 'Please enter id enterpise',
  'Mes.Enterprise.NotFound': 'Not found',
  'Mes.Enterprise.Name.Required': 'Please enter name enterprise',
  'Mes.Enterprise.Name.OverLength': 'Name is too long',
  'Mes.Enterprise.Description.OverLength': 'Description is too long',
  'Mes.Enterprise.Code.Required': 'Please enter code enterprise',
  'Mes.Enterprise.Code.OverLength': 'The code is too long',
  'Mes.Enterprise.Code.AlreadyExist': 'Code already exists',
  'Mes.Enterprise.Create.Successfully': 'Successfully created',
  'Mes.Enterprise.Update.Successfully': 'Updated successful',
  'Mes.Enterprise.Delete.Successfully': 'Deleted successfully',
  'Mes.Enterprise.GetDetail.Successfully': 'Get details successfully',
  'Mes.Enterprise.Search.Successfully': 'Search success',
  'Mes.Market.Id.Required': 'Please enter id market',
  'Mes.Market.NotFound': 'Not found',
  'Mes.Market.Name.Required': 'Please enter name market',
  'Mes.Market.Name.OverLength': 'Name is too long',
  'Mes.Market.Description.OverLength': 'Description is too long',
  'Mes.Market.Code.Required': 'Please enter code market',
  'Mes.Market.Code.OverLength': 'The code is too long',
  'Mes.Market.Code.AlreadyExist': 'Code already exists',
  'Mes.Market.Create.Successfully': 'Successfully created',
  'Mes.Market.Update.Successfully': 'Updated successful',
  'Mes.Market.Delete.Successfully': 'Deleted successfully',
  'Mes.Market.GetDetail.Successfully': 'Get success details',
  'Mes.Market.Search.Successfully': 'Successful Search',
  'Mes.TargetCustomer.Id.Required': 'Please enter id target customer',
  'Mes.TargetCustomer.NotFound': 'Not found',
  'Mes.TargetCustomer.Name.Required': 'Please enter name target customer',
  'Mes.TargetCustomer.Name.OverLength': 'Name is too long',
  'Mes.TargetCustomer.Description.OverLength': 'Description is too long',
  'Mes.TargetCustomer.Code.Required': 'Please enter code target customer',
  'Mes.TargetCustomer.Code.OverLength': 'Code is too long',
  'Mes.TargetCustomer.Code.AlreadyExist': 'Code already exists',
  'Mes.TargetCustomer.Create.Successfully': 'Successfully created',
  'Mes.TargetCustomer.Update.Successfully': 'Updated successful',
  'Mes.TargetCustomer.Delete.Successfully': 'Deleted successfully',
  'Mes.TargetCustomer.GetDetail.Successfully': 'Get details successfully',
  'Mes.TargetCustomer.Search.Successfully': 'Search success',
  'Mes.Field.Id.Required': 'Please enter id field',
  'Mes.Field.NotFound': 'Not found',
  'Mes.Field.Name.Required': 'Please enter name field',
  'Mes.Field.Name.OverLength': 'Name is too long',
  'Mes.Field.Description.OverLength': 'Description is too long',
  'Mes.Field.Code.Required': 'Please enter field code ',
  'Mes.Field.Code.OverLength': 'The code is too long',
  'Mes.Field.Code.AlreadyExist': 'Code already exists',
  'Mes.CareerId.NotFound': 'Not found',
  'Mes.Field.CareerId.Required': 'Please enter id field carrer',
  'Mes.Field.Create.Successfully': 'Successfully created',
  'Mes.Field.Update.Successfully': 'Updated successful',
  'Mes.Field.Delete.Successfully': 'Deleted successfully',
  'Mes.Field.GetDetail.Successfully': 'Get details successfully',
  'Mes.Field.Search.Successfully': 'Search success',
  'Mes.FieldRelation.Id.Required': 'Please enter id field relation',
  'Mes.FieldRelation.NotFound': 'Not found',
  'Mes.FieldRelation.Name.Required': 'Please enter name field relation',
  'Mes.FieldRelation.Name.OverLength': 'Name is too long',
  'Mes.FieldRelation.Description.OverLength': 'Description is too long',
  'Mes.Media.Id.Required': 'Please enter id media',
  'Mes.Media.NotFound': 'Not found',
  'Mes.Media.File.Required': 'Please enter file media',
  'Mes.Media.Files.Required': 'Please enter files media',
  'Mes.Media.Create.Successfully': 'Successfully created',
  'Mes.Media.Delete.Successfully': 'Deleted successfully',
  'Mes.Media.GetDetail.Successfully': 'Get details successfully',
  'Mes.Media.Search.Successfully': 'Successful search',
  'Mes.Media.File.InValid': 'Invalid file',

  'Mes.AppUser.NotFound': 'Not found',

  'Mes.Fee.Search.Successfully': 'Successful membership search',
  'Mes.FeeHistory.Search.Successfully': 'Successful membership history search',
  'Mes.Sponsor.Search.Successfully': 'Successful sponsorship search',

  'Mes.RefreshToken.Token.InValid': 'Invalid Token',
  'Mes.RefreshToken.Token.Expired': 'Token has expired',
  'Mes.RefreshToken.Token.NotFound': 'Not found',
  'Mes.LeaderInfo.EmailAddress.Required': 'Please enter email address leader info',
  'Mes.LeaderInfo.EmailAddress.InValid': 'Invalid email',
  'Mes.LeaderInfo.PhoneNumber.Required': 'Please enter phone number leader info',
  'Mes.LeaderInfo.PhoneNumber.InValid': 'Invalid phone number',
  'Mes.LeaderInfo.Name.Required': 'Please enter name leader info',
  'Mes.LeaderInfo.Name.OverLength': 'Name is too long',
  'Mes.LeaderInfo.Position.Required': 'Please enter position leader info',
  'Mes.LeaderInfo.Position.OverLength': 'The title is too long',
  'Mes.LeaderInfo.Gender.Required': 'Please enter gender leader info',
  'Mes.LeaderInfo.Gender.InValid': 'Invalid gender',
  'Mes.ConnectInfo.EmailAddress.Required': 'Please enter email connect info',
  'Mes.ConnectInfo.EmailAddress.InValid': 'Email is invalid',
  'Mes.ConnectInfo.PhoneNumber.Required': 'Please enter phone number',
  'Mes.ConnectInfo.PhoneNumber.InValid': 'Invalid phone number',
  'Mes.ConnectInfo.Name.Required': 'Please enter name connect info',
  'Mes.ConnectInfo.Name.OverLength': 'Name is too long',
  'Mes.ConnectInfo.Position.Required': 'Please enter position connect info',
  'Mes.ConnectInfo.Position.OverLength': 'Position is too long',
  'Mes.ConnectInfo.Gender.Required': 'Please enter gender connect info',
  'Mes.ConnectInfo.Gender.InValid': 'Invalid gender',
  'Mes.Member.Id.Required': 'Please enter id member',
  'Mes.Member.NotFound': 'Not found',
  'Mes.Member.Name.Required': 'Please enter name member',
  'Mes.Member.Name.OverLength': 'Name is too long',
  'Mes.Member.EnterpriseName.Required': 'Please enter business name',
  'Mes.Member.EnterpriseName.OverLength': 'Business name is too long',
  'Mes.Member.EnterpriseEnglishName.OverLength': 'Business english name is too long',
  'Mes.Member.EnterpriseAcronymsName.OverLength': 'The enterprise acronyms name is too long',
  'Mes.Member.Represent.Required': 'Please enter represent member',
  'Mes.Member.Represent.OverLength': 'Represent too long',
  'Mes.Member.TransactionOfficeAddress.Required': 'Please enter for transaction office address',
  'Mes.Member.TransactionOfficeAddress.OverLength': 'The transaction office address is too long',
  'Mes.Member.TaxCodeAddress.Required': 'Please enter tax code member',
  'Mes.Member.TaxCodeAddress.OverLength': 'The code is too long',
  'Mes.Member.Website.OverLength': 'Website too long',
  'Mes.Member.Fanpage.OverLength': 'Fanpage is too long',
  'Mes.Member.FieldRelationIds.Duplicate': 'Duplicate id',
  'Mes.Member.FieldRelationIds.Required': 'Please enter id field relation member ',
  'Mes.Member.MarketId.Required': 'Please enter market id',
  'Mes.Member.TargetCustomerId.Required': 'Please enter target customer id',
  'Mes.Member.CertificateId.Required': 'Please enter certificate id',
  'Mes.Member.EnterpriseId.Required': 'Please enter business id',
  'Mes.Member.OrganizationId.Required': 'Please enter organization id',
  'Mes.Member.LeaderInfo.Required': 'Please enter leader information',
  'Mes.Member.ConnectInfos.Required': 'Please enter contact information',
  'Mes.Member.IdCard.Required': 'Please enter id card member',
  'Mes.Member.IdCard.OverLength': 'Id card is too long',
  'Mes.Member.WorkUnit.Required': 'Please enter unit work member',
  'Mes.Member.WorkUnit.OverLength': 'The work unit is too long',
  'Mes.Member.Position.Required': 'Please enter position member',
  'Mes.Member.Position.OverLength': 'The position is too long',
  'Mes.Member.DayOfBirth.Required': 'Please enter birthday member',
  'Mes.Member.Gender.Required': 'Please enter gender member',
  'Mes.Member.Gender.InValid': 'Invalid gender',
  'Mes.Member.Avatar.InValid': 'Invalid avatar',
  'Mes.Member.PartnerSource.Required': 'Please enter partner source',
  'Mes.Member.PartnerSource.InValid': 'Partner source Invalid ',
  'Mes.Member.EmailAddress.Required': 'Please enter email address',
  'Mes.Member.EmailAddress.InValid': 'Invalid email address',
  'Mes.Member.PhoneNumber.Required': 'Please enter phone number',
  'Mes.Member.PhoneNumber.InValid': 'Invalid phone number',
  'Mes.Member.EnterpriseScale.Required': 'Please enter enterprise scale',
  'Mes.Member.EnterpriseScale.InValid': 'Invalid enterprise scale',
  'Mes.Member.Create.Successfully': 'Successfully created',
  'Mes.Member.Update.Successfully': 'Update successful',
  'Mes.Member.Delete.Successfully': 'Successfully deleted',
  'Mes.Member.GetDetail.Successfully': 'Get details successfully',
  'Mes.Member.Search.Successfully': 'Successful search',
  'Mes.Member.Create.Failed': 'Create failed',
  'Mes.Member.Update.Failed': 'Update failed',
  'Mes.Sponsor.Id.Required': 'Please enter sponsor id',
  'Mes.Sponsor.NotFound': 'Sponsor does not exist',
  'Mes.Sponsor.Product.Required': 'Please enter sponsored product',
  'Mes.Sponsor.Product.OverLength': 'Sponsored product is too long',
  'Mes.Sponsor.Content.Required': 'Please enter sponsored content',
  'Mes.Sponsor.Content.OverLength': 'Sponsored content is too long',
  'Mes.Sponsor.Unit.Required': 'Please enter sponsor',
  'Mes.Sponsor.Unit.OverLength': 'Sponsor unit too long',
  'Mes.Sponsor.UnitPrice.Required': 'Request for sponsorship unit price',
  'Mes.Sponsor.Quantity.Required': 'Please enter number of sponsor products',
  'Mes.Sponsor.SponsorDate.Required': 'Please enter sponsor date',
  'Mes.Sponsor.Create.Successfully': 'Successful sponsorship creation',
  'Mes.Sponsor.Update.Successfully': 'Successful sponsorship update',
  'Mes.Sponsor.Delete.Successfully': 'Successful sponsorship removal',
  'Mes.Sponsor.GetDetail.Successfully': 'View successful sponsorship details',
  'Mes.User.PleaseCheckEmail': 'Please check email',
  'Mes.User.Id.Required': 'Please enter user id',
  'Mes.User.NotFound': 'Not found',
  'Mes.User.IsLocked': 'User is locked',
  'Mes.User.PhoneNumber.Required': 'Please enter user phone number',
  'Mes.User.PhoneNumber.InValid': 'Invalid user phone number',
  'Mes.User.EmailAddress.Required': 'Please enter user email address',
  'Mes.User.EmailAddress.InValid': 'Request user email address',
  'Mes.User.EmailAddress.NotFound': 'Not found',
  'Mes.User.Content.Required': 'Please enter user content',
  'Mes.User.Content.OverLength': 'User content is too long',
  'Mes.User.Name.Required': 'Please enter username ',
  'Mes.User.Name.OverLength': 'Username is too long',
  'Mes.User.Avatar.InValid': 'Invalid user profile picture',
  'Mes.User.OperationStatus.Required': 'Please enter user activity status',
  'Mes.User.Password.InValid': 'Invalid password',
  'Mes.User.Password.NotEnoughLength': 'Password not long enough',
  'Mes.User.DayOfBirth.Required': 'Please enter user birthday user',
  'Mes.User.RecoveryCode.Expired': 'Request for recovery code',
  'Mes.User.RecoveryCode.NotFound': 'Not found',
  'Mes.User.Create.Successfully': 'Successfully created',
  'Mes.User.Update.Successfully': 'Update successful',
  'Mes.User.Delete.Successfully': 'Successfully deleted',
  'Mes.User.GetDetail.Successfully': 'Get details successfully',
  'Mes.User.Search.Successfully': 'Search success',
  'Mes.User.Profile.Successfully': 'Success Profile',
  'Mes.User.SignIn.Successfully': 'Login successful',
  'Mes.User.Refresh.Successfully': 'Refresh successful',
  'Mes.User.Signout.Successfully': 'Logout successful',
  'Mes.User.SendMail.Successfully': 'Email sent successfully',
  'Mes.User.SendMail.Failed': 'Email delivery failed',
  'Mes.User.ResetForgotPassword.Successfully': 'Password reset successful',
  'Mes.User.CheckRecoveryToken.Successfully': 'Test recovery token successful',
  'Mes.User.UpdateProfile.Successfully': 'Update profile successfully',
  'Mes.User.Username.NotFound': 'The username is wrong',

  'Mes.Role.Id.Required': 'Please enter id role',
  'Mes.Role.NotFound': 'Not found',
  'Mes.Role.Name.Required': 'Please enter name role',
  'Mes.Role.Name.OverLength': 'Name is too long',
  'Mes.Role.Create.Successfully': 'Successfully created',
  'Mes.Role.Update.Successfully': 'Update successful',
  'Mes.Role.Delete.Successfully': 'Deleted successfully',
  'Mes.Role.GetDetail.Successfully': 'Get details successfully',
  'Mes.Role.Search.Successfully': 'Successful search',
  'Mes.Permission.Code.Required': 'Please add the permission',
  'Mes.Permission.Code.NotFound': 'Not found code',
  'Mes.Permission.NotFound': 'Not found',
  'Mes.Permission.GetAll.Successfully': 'Get all success',
  'server.notFound': 'Not found',
  'server.networkError': 'Network error',
  'Server.AddBeat.Success': 'Adding success',
  'Server.DeleteBeat.Success': 'Deleting success',
  'Server.UpdateBeat.Success': 'Updating success',
  'Mes.Users.Add.Successfully': 'Add successful account',
  'Mes.Users.DeleteUsers.Successfully': 'Successfully delete',
  'Mes.Users.UpdateSuccessfully': 'successfulAccountInformationUpdates',
  'Mes.Researchs.Update.Successfully': 'Updating successful research',
  'Mes.Researchs.Add.Successfully': 'Add a successful study',
  'Mes.Researchs.Delete.Successfully': 'Delete successful research',
  'Mes.User.NotDeletePermission': "Can't delete this user",
  'Mes.Role.NotDeletePermission': "Can't delete this right",
  'Mes.Member.Invite.Successfully': 'Invite successful members',
  'Mes.Member.Id.InValid': 'The user ID is invalid',
  'Mes.FeeHistory.Year.InValid': 'Year of the festival is invalid festival',
  'Mes.FeeHistory.Pending': 'Not yet completed the festival',
  'Mes.Member.FeeHistories.InValid': 'Members pay invalid fees',
  'Mes.Fee.Create.Successfully': 'Successful fees',

  'Mes.Member.Code.AlreadyExist': 'Code already exists',
  'Mes.AppUser.EmailAddress.AlreadyExist': 'Email already exists',
  'Mes.ClubRepresent.Code.AlreadyExist': 'Code already exists',
  'Mes.ClubRepresent.Create.Successfully': 'Successfully created',
  'Mes.ClubRepresent.Update.Successfully': 'Updating success',
  'Mes.ClubRepresent.Delete.Successfully': 'Deleting success',

  'Mes.Career.IsUsing': 'Industry is being used',
  'Mes.Certificate.IsUsing': 'Certificate being used',
  'Mes.Enterprise.IsUsing': 'Business is being used',
  'Mes.Field.IsUsing': 'Field being used',
  'Mes.Market.IsUsing': 'Market is being used',
  'Mes.TargetCustomer.IsUsing': 'Target customers are being used',
  'Mes.Organization.IsUsing': 'Organization is being used',
  'Mes.Contact.Update.Successfully': 'Update contact successfully',
  'Mes.Role.StillExistUser': 'Can not erase the role have been used ',
  'Mes.Schedule.AttachPath.OverLength': "Can't add files more than 6MB or 3 files at the same time",
  'Mes.Notification.Attach.OverLength': "Can't add files more than 6MB or 3 files at the same time",
  'Mes.Fee.Attach.OverLength': "Can't add files more than 6MB or 3 files at the same time",
  'Mes.Media.File.OverLength': "Can't add files more than 6MB or 3 files at the same time",
  'Mes.Media.Files.OverLength':
    "Can't add files more than 6MB or more than 3 files at the same time",
  'Mes.User.Id.InValid': "Can't delete operating users",

  'Mes.Member.Duplicate': 'Duplicate login code or email',
  'Mes.OnlySupport.Excel': 'Only Excel files are supported',
  'Mes.Member.Import.Successfully': 'Successfully',
  'Mes.ClubRepresent.Import.Successfully': 'Successfully',
  'Mes.FieldRelation.AlreadyExist.Code': 'Subscribers have existed',
  'Mes.LeaderInfo.EmailAddress.AlreadyExist': 'Leadership email has existed',
  'Mes.User.Username.AlreadyExist': 'The username has existed',

  'Mes.User.EmailAddress.AlreadyExist': 'Email has existed',
  'Mes.LeaderInfo.EmailAddress.Duplicate': 'Mail of unit must different email of leader',
  'Mes.FieldRelation.Code.Required': 'Subscribed group code',

  // added on 7/25/2023
  'Mes.Activity.Create.Successfully': 'Create event successfully',
  'Mes.Activity.Update.Successfully': 'Update event successfully',
  'Mes.Activity.Delete.Successfully': 'Delete event successfully',
  'Mes.MeritBadge.Create.Successfully': 'Create merit badges successfully',
  'Mes.MeritBadge.Update.Successfully': 'Update merit badges successfully',
  'Mes.MeritBadge.Delete.Successfully': 'Delete merit badges successfully',
  'Mes.Suggest.Approve.Successfully': 'Approve suggest successfully',
  'Mes.Suggest.Reject.Successfully': 'Reject suggest successfully',
  'Mes.Suggest.Delete.Successfully': 'Delete suggest successfully',
  'Mes.Reward.Delete.Successfully': 'Delete reward successfully',
  'Mes.Report.Create.Successfully': 'Upload report successfully',
  'Mes.Report.Delete.Successfully': 'Delete report successfully',
  'Mes.User.NotAllowed': 'User not allowed',
  'Mes.Activity.Title.Required': 'Name activity is required',
  'Mes.Activity.Title.OverLength': 'Name activity is over length',
  'Mes.Activity.Content.Required': 'Content activity is required',
  'Mes.Activity.Content.OverLength': 'Content activity is over length',
  'Mes.Activity.StartTime.Required': 'Time start is required',
  'Mes.Activity.EndTime.Required': 'Time end is required',
  'Mes.Activity.EndTime.InValid': 'Time end must greater than time start',
  'Mes.Activity.NotFound': 'Activity not found',
  'Mes.Activity.NotAllowed': 'Activity not allowed',
  'Mes.MeritBadge.NotFound': 'Merit badges not found',
  'Mes.MeritBadge.Id.Required': 'Merit badges is required',
  'Mes.MeritBadge.Id.Duplicate': 'Merit badges bị lặp lại',
  'Mes.MeritBadge.Code.Required': 'Code merit badges is required',
  'Mes.MeritBadge.Code.OverLength': 'Code merit badges less than 255 character',
  'Mes.MeritBadge.Code.AlreadyExist': 'Code merit badges already exist',
  'Mes.MeritBadge.Name.Required': 'Name merit badges is required',
  'Mes.MeritBadge.Name.OverLength': 'Name merit badges less than 255 character',
  'Mes.MeritBadge.Description.OverLength': 'Description merit badges less than 255 character',
  'Mes.Member.Grade.Required': 'Grade is required',
  'Mes.Member.Grade.InValid': 'Grade invalid',
  'Mes.Member.MustFromClub': 'Member must from club',
  'Mes.Member.Id.Duplicate': 'Id of Member is duplicate',
  'Mes.Activity.Grades.Required': 'Please select Grade',
  'Mes.Activity.Grades.Duplicate': 'Grade is duplicate',
  'Mes.Activity.Score.Required': 'Score is required',
  'Mes.Activity.Score.NotEnoughLength': 'Score must than 0',
  'Mes.Activity.NotStart': 'Activity not start',
  'Mes.Activity.HadStarted': 'Activity had start',
  'Mes.Activity.HadConfirmed': 'Activity had confirmed',
  'Mes.Suggests.Status.MustWaiting': 'Activity must confirmed',
  'Mes.Reward.NotFound': 'Reward not found',
  'Mes.CreateRewardSuggestRequest.MemberIds.Required': 'Please enter member',
  'Mes.CreateRewardSuggestRequest.MemberIds.InValid': 'Id Member is duplicate',
  'Mes.DeleteManyRewardRequest.Ids.Required': 'Id required',
  'Mes.DeleteManyRewardRequest.Ids.InValid': 'Id is duplicate',
  'Mes.CreateRewardSuggestRequest.ProposedReason.Required': 'Reason proposed required',
  'Mes.CreateRewardSuggestRequest.RejectReason.Required': 'Reason reject is required',
  'Mes.Report.NotFound': 'Report not found',
  'Mes.Report.Name.Required': 'Name report is required',
  'Mes.Report.Name.OverLength': 'Name report less than 255 character',
  'Mes.Report.AttachFile.Required': 'File attach required',
  'Mes.Report.AttachFile.InValid': 'File attach invalid',
  'Mes.Activity.OtherEmails.Duplicate': 'Other email is duplicate',
  'Mes.Activity.Attach.OverLength': 'Attach file cant over 3 files and each file cant over 6mb',
  // update on 18/10/2023
  'Mes.Feedback.Id.Required': 'Please enter id feedback',
  'Mes.Feedback.NotFound': 'Feedback is not found',
  'Mes.Feedback.Tittle.Required': 'Please enter feedback name',
  'Mes.Feedback.Tittle.OverLength': 'Feedback name is too long',
  'Mes.Feedback.Content.Required': 'Please enter feedback content',
  'Mes.Feedback.Content.OverLength': 'Feedback content is too long',
  'Mes.Feedback.ContentAttachs.OverLength': 'Attach file over allowed size',
  'Mes.Feedback.ReplyAttachs.OverLength': 'Attach file over allowed size',
  'Mes.Feedback.Create.Successfully': 'Create feedback successfully',
  'Mes.Feedback.Update.Successfully': 'Update feedback successfully',
  'Mes.Feedback.Delete.Successfully': 'Delete feedback successfully',
  'Mes.Feedback.GetDetail.Successfully': 'Get feedback detail successfully',
  'Mes.Feedback.Search.Successfully': 'Search feedback successfully',

  // update on 05/01/2024
  // Document
  'Mes.Document.NotFound': 'Information not found',
  'Mes.Document.Tittle.Required': 'Please enter name information',
  'Mes.Document.CoverImage.Required': 'Please enter cover image information',
  'Mes.Document.Tittle.OverLength': 'Name information too long',
  'Mes.Document.Content.Required': 'Please enter content information',
  'Mes.Document.Content.OverLength': 'Content information too long',
  'Mes.Document.Description.OverLength': 'Description information too long',
  'Mes.Document.ClubIds.Duplicate': 'Club is duplicate',
  'Mes.Document.ClubIds.NotFound': 'Club not found',
  'Mes.Document.MemberTypes.Required': 'Please enter member type information',
  'Mes.Document.MemberTypes.Duplicate': 'Member type is duplicate',
  'Mes.Document.MemberTypes.InValid': 'Member type invalid',
  'Mes.Document.Ids.Required': 'Required id information',
  'Mes.Document.Create.Successfully': 'Create information successfully',
  'Mes.Document.Create.Failed': 'Create information failed',
  'Mes.Document.Update.Successfully': 'Update information successfully',
  'Mes.Document.Update.Failed': 'Update information failed',
  'Mes.Document.Delete.Successfully': 'Delete information successfully',
  'Mes.Document.Delete.Failed': 'Delete information failed',
  'Mes.Document.GetDetail.Successfully': 'Get detail information successfully',
  'Mes.Document.Search.Successfully': 'Search information successfully',

  // CoverImage
  'Mes.CoverImage.Description.OverLength': 'Description cover image too long',
  'Mes.CoverImage.File.InValid': 'Description cover image too long',
  'Mes.Upload.Create.Successfully': 'Upload image successfully',

  'Mes.Files.Misalignment': "Excel file doesn't match the template",
  'Mes.Feedback.Reply.Successfully': 'Reply feedback successfully',
  'Mes.Member.EmailAddress.AlreadyExist': 'Email has existed',

  // update on 20/08/2024
  'Mes.MemberModification.NotFound': 'Modification request does not exist',
  'Mes.MemberModification.NotAllowed':
    'Modification request is pending updates and cannot be approved at this time.',
  'Mes.MemberModification.MemberType.NotSupport': 'This member type does not support modification',
  'Mes.MemberModification.GetDetail.Successfully':
    'Successfully retrieved modification request details',
  'Mes.MemberModification.Search.Successfully':
    'Successfully retrieved the list of modification requests',
  'Mes.MemberModification.Create.Successfully': 'Successfully created modification request',
  'Mes.MemberModification.Accept.Successfully': 'Successfully approved modification request',
  'Mes.MemberModification.Reject.Successfully': 'Successfully rejected modification request',
  'Mes.Directory.Name.AlreadyExist':
    'The directory name already exists when creating a new directory',
  'Mes.Directory.Create.Successfully': 'Directory created successfully',
  'Mes.Directory.Id.NotFound': 'The directory ID to delete or update was not found',
  'Mes.Directory.Delete.Successfully': 'Directory deleted successfully',
  'Mes.Directory.Update.Successfully': 'Directory name updated successfully',
  'Mes.Media.Update.Successfully': 'File updated successfully',
};
