export default {
  'home.name': 'Documents',
  'home.title': 'Archives',
  'home.fileName': 'File name',
  'home.fullPath': 'Path',
  'home.contentType': 'File type',
  'home.createdAt': 'Date created',
  'home.confirm.title.delete': 'Delete the file',
  'home.confirm.content.delete': 'Do you agree to delete these files?',
  'home.create': 'Add new file',
  'home.action': 'Action',
  'home.memberSelect': 'Members participating',
  'home.memberSelect-placeholder': 'Select members participating',
  'home.accepts': 'Members participating',
  'home.upload': 'Upload file',
  'home.clubs': 'Club',
  'home.formAdd.code': 'Member code',
  'home.formAdd.enterpriseName': 'Club',
  'home.formAdd.emailAddress': 'Email',
  'home.formAdd.type': 'Member type',
  'home.formAdd.name': 'Member name',
  'home.maxFile': 'Unable to add more than 1 file at the same time',
  'home.requiredFile': 'Chose upload file',
  'dashboard.name': 'Dashboard',
  'home.input.name': 'File name',
  'home.placeholder.input.name': 'Enter file name',
  'home.validate-file': 'Only one file can be uploaded, and its size must not exceed 60MB.',
  'home.upload.content': 'Only one file upload is supported',
  'home.folder.information': 'Folder Information',
  'home.folder.update': 'Edit Folder',
  'home.folder.create': 'Add New Folder',
  'home.folder.input.name': 'Folder Name',
  'home.folder.placeholder.input.name': 'Enter folder name',
  'home.addFolder': 'Add New Folder',
  'home.uploadFile': 'Upload File',
  'home.editFile': 'Edit File',
  'home.downloadFile': 'Download File',
  'home.upload.file.title': 'Click or drag a file here',
  'home.upload.file.content': 'Supported files: PDF, Word, Excel',
  'home.modal.upload': 'Upload File',
  'home.modal.change.name': 'Rename File',
  'home.file.validate': 'File and file name cannot be empty',
  'home.file.delete': 'Deleted Successfully',
  'home.sort': 'Sort',
  'home.sort.by-name.asc': 'By Name Ascending',
  'home.sort.by-name.desc': 'By Name Descending',
  'home.sort.by-createdDate.asc': 'By Creation Date Ascending',
  'home.sort.by-createdDate.desc': 'By Creation Date Descending',
  'home.select-all': 'Select All',
  'home.un-select-all': 'Unselect All',
  'home.information': 'File Details',
  'home.update': 'Edit File',
  'home.changeFile': 'Change File',
  'home.home.validate': 'Folder name must not empty',
  'home.file.name.validate': 'File name must not empty',
};
