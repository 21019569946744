export const logo = require('./logo.png');
export const error = require('./error.png');
export const imgAvatar = require('./avatar.jpg');
export const iconDot = require('./dotIcon.png');

export { default as rightImgLogin } from './right-login.png';

export { default as vietnam } from './vietnam.png';
export { default as english } from './english.png';
export { default as sponsors_icon } from './Sponsors_icon.png';
export { default as fees_icon } from './Fees_icon.png';
export { default as Pdf_icon } from './pdf_icon.png';
export { default as Word_icon } from './word_icon.png';
export { default as Audio_icon } from './audio_icon.png';
export { default as Txt_icon } from './txt_icon.png';
export { default as Rar_icon } from './rar_icon.png';
export { default as Zip_icon } from './zip_icon.png';
export { default as Unknown_icon } from './unknown_icon.png';
export { default as template1 } from './template-1.png';
export { default as template2 } from './template-2.png';
export { default as template3 } from './template-3.png';
export { default as template4 } from './template-4.png';
export { default as template5 } from './template-5.png';

export { default as img_folder_icon } from './img_folder_icon.png';
export { default as img_doc_icon } from './img_doc_icon.png';
export { default as img_pdf_icon } from './img_pdf_icon.png';
export { default as img_xls_icon } from './img_xls_icon.png';
